import React from "react"
import { Card, Icon, Typography } from "antd"
import { formatDate } from "../utils/dateFunc"
import { useEventsActions } from "../contexts/eventContext"
import Img from "./common/img"

const EventCard = ({ event }) => {
  const eventActions = useEventsActions()
  return (
    <Card
      className="event-item"
      onClick={() => eventActions.openDrawer(event)}
      cover={
        <Img
          alt={event.image && event.image.name}
          src={event.image && event.image.path}
        />
      }
    >
      <div className="event-title">
        <Typography.Title level={4} ellipsis>
          {event.name}
        </Typography.Title>
      </div>
      <div className="event-subtitle">{event.categoryName}</div>
      <div className="event-info">
        <div className="event-location">
          <Icon type="environment" /> {event.locationName}
        </div>
        <div className="event-date">
          <Icon type="calendar" />{" "}
          {`${formatDate(event.dateStart)} - ${formatDate(event.dateStop)}`}
        </div>
      </div>
      <div className="event-ticket-info">
        <div className="event-ticket-type">{event.ticketinfo}</div>
        {event.priceVisible && (
          <div className="event-ticket-price">
            {event.price ? `${event.price} DKK` : "Gratis"}
          </div>
        )}
      </div>
    </Card>
  )
}

export default EventCard
