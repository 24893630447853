import React from "react"
import { List, Row, Col } from "antd"
import { Link } from "react-router-dom"

const CompanyListItem = ({ contact, selected }) => {
  return (
    <List.Item className={selected === contact.id ? "selected" : ""}>
      <Link replace to={`/contacts/${contact.id}`}>
        <List.Item.Meta
          title={
            <Row type="flex">
              <Col className="col-grow">
                <span className="contact-name">{contact.name}</span>
              </Col>
              <Col>
                <span className="text-muted">{`${contact.employeesQty} kontaktpersoner`}</span>
              </Col>
            </Row>
          }
          description={
            <Row>
              <Col span={8}>{contact.type}</Col>
              <Col span={8}>{contact.phone}</Col>
              <Col span={8} className="text-right">
                {contact.email}
              </Col>
            </Row>
          }
        />
      </Link>
    </List.Item>
  )
}

export default CompanyListItem
