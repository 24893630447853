import React, { useState } from "react"
import { Upload, Button, Tooltip, Row, Col, Checkbox } from "antd"
import { getDataFromFile } from "../../services/participantsUploadService"
import { excelTemplateOrder } from "../../config.json"

const ImportParticipants = ({ onImport }) => {
  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState(false)
  const [withTitle, setWithTitle] = useState(false)
  const [file, setFile] = useState({})

  const beforeUpload = file => {
    global.log("file:", file)
    setFile(file)
    return false
  }
  const handleUpload = async () => {
    setUploading(true)
    try {
      const { data: order } = await getDataFromFile(file, withTitle)
      global.log(order)
      onImport(order)
    } catch (error) {
      global.log(error)
    }
    setFile({})
    setUploading(false)
  }
  const handleDelete = () => setFile({})

  const props = {
    beforeUpload,
    multiple: false,
    showUploadList: false,
    accept:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  }
  return (
    <div className="participants-upload">
      {isEmpty(file) ? (
        <Upload {...props}>
          <Tooltip
            title={
              <span>
                Du kan kun importere csv-fil.
                <br /> Hent deltagere skabelon fil{" "}
                <Button
                  type="link"
                  onClick={e => {
                    e.stopPropagation()
                    window.open(excelTemplateOrder, "_blank")
                  }}
                  target="_blank"
                >
                  her
                </Button>
              </span>
            }
          >
            <Button className="participants-upload-btn" icon="upload">
              Importere
            </Button>
          </Tooltip>
        </Upload>
      ) : (
        <>
          <Button
            onClick={handleDelete}
            className="remove-file-btn"
            icon="close"
          >
            Annuller Import
          </Button>
          <div className="file-to-upload">
            <Row type="flex" align="middle">
              <Col className="col-grow">
                <h3>{file.name}</h3>
                <Checkbox
                  checked={withTitle}
                  onChange={() => setWithTitle(!withTitle)}
                >
                  Første række indeholder kolonnenavnene
                </Checkbox>
              </Col>
              <Col>
                <Button onClick={handleUpload} type="primary" icon="upload">
                  Upload
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  )
}

const isEmpty = obj =>
  !!(Object.entries(obj).length === 0 && obj.constructor === Object)

export default ImportParticipants
