import React, { Component } from "react"
import { Form, Affix, message, Row, Col, Card, Button } from "antd"
import EventPreview from "../components/eventPreview"
import ParticipantSearch from "../components/ParticipantSearch"
import OrderEmployeeFields from "../components/orderEmployeeFields"
import { getEventTicketType } from "../services/eventTicketTypeService"
import {
  sumPrice,
  sumQty,
  sumParticipants,
  sumTotalPrice
} from "../utils/getTicketSum"
import ImportParticipants from "../components/common/importParticipants"
import { getInviteReasons, placeOrder } from "../services/inviteReasonService"
import { getParticipantTypes } from "../services/participantTypesService"
import TotalTickets from "../components/totalTickets"
import OrderResult from "../components/orderResult"
import RepeatedCard from "../components/repeatedComponents/repeatedCard"
import ParticipantCompanyItem from "../components/formFields/participantCompanyItem"
import LoadingSpin from "../components/common/loadingSpin"

class OrderTicketsForm extends Component {
  state = {
    isModalVisible: false,
    loading: false,
    fillLater: false,
    ticketsQty: 0,
    ticketsTotalPrice: 0,
    response: { url: "" }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.populateOrder()
    this.setState({ loading: false })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { validateFields } = this.props.form
    const { id, sid } = this.props.match.params

    // this.setState({loading: true})
    validateFields(async (err, values) => {
      if (!err) {
        if (
          typeof values.companies !== "undefined" &&
          typeof values.companies[0].participants !== "undefined"
        ) {
          const orderObj = {
            eventId: Number(id),
            sponsorshipId: Number(sid),
            companies: values.companies,
            participateMyself: values.participateMyself,
            message: values.message,
            sponsorshipTicketEventPeriodId:
              values.sponsorshipTicketEventPeriodId
          }

          global.log("Received values of form: ", orderObj)
          try {
            const { data: response } = await placeOrder(orderObj)
            this.showModal()
            this.setState({ response })
            global.log(response)
          } catch (error) {
            global.log(error)
          }
        } else message.error("Tilføje Deltager")
        // this.setState({loading: false})
        // message.success("Bestilling er sendt", 3)
        // this.props.history.push("/orderTickets")
        // global.log(values)
      }
    })
  }

  showModal = () => {
    this.setState({ isModalVisible: true })
  }

  /*
  handleFillLater = () => {
    const {setFieldsValue} = this.props.form,
      fillLater = this.state.fillLater
    if (fillLater) {
      this.setState({
        fillLater: !fillLater,
        ticketsQty: 0,
        ticketsTotalPrice: 0,
      })
      global.log("clear Antal", fillLater)
    } else {
      setFieldsValue({
        companies: [],
        initParticipantCo: [],
      })
      this.setState({
        companiesKeys: [],
        fillLater: !fillLater,
        ticketsQty: 0,
        ticketsTotalPrice: 0,
      })
    }
  };
 */
  handleSelectedCompany = company => {
    const { setFieldsValue, getFieldValue } = this.props.form
    const keys = getFieldValue("companiesKeys")
    const initial = getFieldValue("initialCompanies")

    // check if company is added already and show message
    const isAdded = Object.keys(initial).find(
      key => initial[key].vatNo === company.vatNo
    )
    if (isAdded) {
      message.warning(
        "Du kan ikke tilføj to firmaer som har det samme cvr nummer",
        3
      )
      return
    }

    const newKey = keys.length === 0 ? 0 : keys[keys.length - 1] + 1
    initial[newKey] = company
    setFieldsValue({
      companiesKeys: keys.concat(newKey),
      initialCompanies: initial
    })
  }

  handleImportedData = data =>
    this.props.form.setFieldsValue({
      companiesKeys: Object.keys(data.companies).map(Number),
      initialCompanies: { ...data.companies },
      participateMyself: data.participateMyself
    })

  handleBackBtn = () => this.props.history.goBack()

  handleQtyChange = () => {
    const { getFieldValue, getFieldsValue } = this.props.form
    const { fillLater } = this.state
    const formValues = getFieldsValue()
    const ticketTypes = getFieldValue("ticketTypes")

    if (!formValues.companies) {
      this.setState({
        ticketsQty: 0,
        ticketsTotalPrice: 0
      })
      return
    }

    if (fillLater) {
      const tickets = getFieldValue("tickets")
      const ticketsQty = sumQty(tickets)
      const ticketsTotalPrice = sumPrice(tickets, ticketTypes)
      this.setState({
        ticketsQty,
        ticketsTotalPrice
      })
    } else {
      const { companies } = formValues
      const employees = companies.map(item => item.participants || []).flat()
      if (employees) {
        const ticketsQty = sumParticipants(employees)
        const ticketsTotalPrice = sumTotalPrice(employees, ticketTypes)
        const employeeTicket = getFieldValue("participateMyself")
        this.setState({
          ticketsQty: employeeTicket ? ticketsQty + 1 : ticketsQty,
          ticketsTotalPrice
        })
      }
    }
  }

  async populateOrder() {
    const { id, sid } = this.props.match.params
    try {
      const { data: ticketTypes } = await getEventTicketType(id, sid)
      const { data: inviteReasons } = await getInviteReasons()
      const { data: participantTypes } = await getParticipantTypes()
      this.props.form.setFieldsValue({
        ticketTypes,
        inviteReasons,
        participantTypes
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  render() {
    const { form, title } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      isModalVisible,
      fillLater,
      response,
      loading,
      ticketsQty,
      ticketsTotalPrice
    } = this.state

    getFieldDecorator("participantTypes", { initialValue: [] })
    getFieldDecorator("inviteReasons", { initialValue: [] })
    getFieldDecorator("ticketTypes", { initialValue: [] })
    getFieldDecorator("companiesKeys", { initialValue: [] })
    getFieldDecorator("initialCompanies", { initialValue: {} })

    const companyKeys = getFieldValue("companiesKeys")

    return (
      <>
        <Row type="flex" align="middle" className="order-header">
          <Col>
            <Button onClick={this.handleBackBtn} icon="arrow-left" />
          </Col>
          <Col className="col-grow">
            <h1>{title}</h1>
          </Col>
        </Row>
        <Form>
          <Row gutter={15}>
            <Col
              sm={{ span: 14 }}
              md={{ span: 16 }}
              xs={{ span: 24 }}
              className="order-form"
            >
              <Card
                title="Deltagere"
                className="card"
                bordered={false}
                /*  actions={[
                  <div
                    className={`card-footer-link ${
                      !fillLater ? "text-right" : "text-left"
                    }`}
                  >
                    <Button type="link" onClick={this.handleFillLater}>
                      {fillLater
                        ? "Udfyld antal billetter ved deltagerne"
                        : "Udfyld deltagerliste senere"}
                    </Button>
                  </div>,
                ]} */
              >
                {loading && <LoadingSpin dehaze />}
                {fillLater ? (
                  {
                    /* <TicketQty onQtyChange={this.handleQtyChange} form={form} /> */
                  }
                ) : (
                  <>
                    <ImportParticipants onImport={this.handleImportedData} />
                    <ParticipantSearch
                      onCompanySelect={this.handleSelectedCompany}
                    />
                    <RepeatedCard
                      keysFieldName="companiesKeys"
                      initialFieldName="initialCompanies"
                      parentName="companies"
                      disableAdd
                      onChange={this.handleQtyChange}
                      form={form}
                    >
                      <ParticipantCompanyItem
                        onQtyChange={this.handleQtyChange}
                      />
                    </RepeatedCard>
                  </>
                )}
                {companyKeys && companyKeys.length > 0 && (
                  <OrderEmployeeFields
                    form={form}
                    onQtyChange={this.handleQtyChange}
                  />
                )}
              </Card>
            </Col>

            {/* Right Side */}
            <Col sm={{ span: 10 }} md={{ span: 8 }} xs={{ span: 24 }}>
              <Affix offsetTop={15}>
                <div>
                  <EventPreview />
                  <TotalTickets
                    ticketsTotalPrice={ticketsTotalPrice}
                    ticketsQty={ticketsQty}
                  />
                  <div className="book-btn">
                    <Button
                      disabled={loading}
                      onClick={this.handleSubmit}
                      size="large"
                      type="primary"
                    >
                      Book
                    </Button>
                  </div>
                </div>
              </Affix>
            </Col>
          </Row>
        </Form>
        <OrderResult
          response={response}
          onCancel={this.handleBackBtn}
          visible={isModalVisible}
        />
      </>
    )
  }
}
export default Form.create()(OrderTicketsForm)
