import React from "react"
import { Layout } from "antd"
import { Link } from "react-router-dom"
import { brandLogoUrl, brandLogoHeight, homePath } from "../config.json"
import NavBar from "../components/navBar"
import UserMenu from "../components/userMenu"
import { useUser } from "../contexts/userContext"

const { Header: AntHeader } = Layout

const Header = () => {
  const user = useUser()
  return (
    <AntHeader
      className={`${!user && "header-login"} content-header`}
      justify="center"
      align="middle"
    >
      <Link to={homePath}>
        <img src={brandLogoUrl} height={brandLogoHeight} alt="OneLounge" />
      </Link>
      {user && <NavBar />}
      {user && <UserMenu user={user} />}
    </AntHeader>
  )
}

export default Header
