import React from "react"
import { Row, Select, Form, Col, Icon, Input } from "antd"
import RepeatedFormCollapse from "../repeatedComponents/repeatedFormCollapse"
import ParticipantItem from "./participantItem"

const { Option } = Select

const ParticipantCompanyItem = ({ initial, form, onQtyChange, parentName }) => {
  const { getFieldDecorator, getFieldValue } = form
  const pn = parentName && parentName.length ? `${parentName}.` : ""
  const participantTypes = getFieldValue("participantTypes")

  return (
    <>
      <Row
        type="flex"
        align="bottom"
        className="repeated-fileds-row"
        gutter={5}
      >
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          {getFieldDecorator(`${pn}id`, {
            initialValue: initial && initial.id
          })(<Input type="hidden" />)}
          {getFieldDecorator(`${pn}name`, {
            initialValue: initial && initial.name
          })(<Input type="hidden" />)}
          {getFieldDecorator(`${pn}vatNo`, {
            initialValue: initial && initial.vatNo
          })(<Input type="hidden" />)}
          <Form.Item>cvr nr. {initial && initial.vatNo}</Form.Item>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item>
            {getFieldDecorator(`${pn}participantTypeId`, {
              rules: [
                {
                  required: true,
                  message: "Påkrævet felt"
                }
              ],
              initialValue: initial && initial.participantTypeId
            })(
              <Select placeholder="Kundetype">
                {typeof participantTypes !== "undefined" &&
                  participantTypes.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item>
            {getFieldDecorator(`${pn}phone`, {
              initialValue: initial && initial.phone
            })(
              <Input
                placeholder="Telefon"
                name="CoPh"
                prefix={<Icon type="phone" />}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(`${pn}email`, {
              initialValue: initial && initial.email
            })(
              <Input
                placeholder="E-mail"
                name="CoEm"
                prefix={<Icon type="mail" />}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <Row>
            <Col span={16}>
              <Form.Item>
                {getFieldDecorator(`${pn}streetName`, {
                  initialValue: initial && initial.streetName
                })(
                  <Input placeholder="Gade" name="CoAd" autoComplete="nope" />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator(`${pn}streetNumber`, {
                  initialValue: initial && initial.streetNumber
                })(
                  <Input
                    placeholder="Gade nr."
                    name="CoAd"
                    autoComplete="nope"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item>
                {getFieldDecorator(`${pn}zipCode`, {
                  initialValue: initial && initial.zipCode
                })(<Input placeholder="Postnummer" name="CoZc" />)}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item>
                {getFieldDecorator(`${pn}city`, {
                  initialValue: initial && initial.city
                })(<Input placeholder="By" name="CoCt" />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="participants-table">
        <RepeatedFormCollapse
          form={form}
          initialValue={initial && initial.participants}
          neverEmpty
          parentName={`${pn}participants`}
          placeHolder="Ny medarbejder"
          title="Deltagere medarbejder"
          onChange={onQtyChange}
        >
          <ParticipantItem onQtyChange={onQtyChange} />
        </RepeatedFormCollapse>
      </div>
    </>
  )
}

export default ParticipantCompanyItem
