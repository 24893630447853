import React from "react"
import { Link } from "react-router-dom"
import { Avatar, List, Row, Col } from "antd"
import { stringToColor } from "../utils/stringToColor"
import { getInitials } from "../utils/getUserInitials"

const PersonListItem = ({ contact, selected }) => {
  const { name, initials, id, company, mobile, email } = contact
  const userColor = name && stringToColor(name)
  const userInitials = initials || (name && getInitials(name))
  return (
    <List.Item className={selected === id ? "selected" : ""}>
      <Link replace to={`/contacts/${company.id}/${id}`}>
        <List.Item.Meta
          avatar={
            <Avatar
              size="large"
              className="avatar-comp"
              style={{ backgroundColor: userColor }}
            >
              {userInitials}
            </Avatar>
          }
          title={<span className="contact-name">{name}</span>}
          description={
            <Row>
              <Col span={8}>{mobile}</Col>
              <Col span={8}>{email}</Col>
              <Col span={8}>
                <span className="text-right d-block of-ellipses">
                  {company.name}
                </span>
              </Col>
            </Row>
          }
        />
      </Link>
    </List.Item>
  )
}

export default PersonListItem
