import React, { useEffect } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { Menu } from "antd"
import { menuItems } from "../routes/appRoutes"

const NavBar = () => {
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname.split("/")[1]
    const currentRoute = menuItems.find(obj => obj.path === `/${pathname}`)
    document.title = currentRoute
      ? `${currentRoute.title} - One Lounge`
      : "One Lounge"
  }, [location])

  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={["/"]}
      selectedKeys={[location.pathname]}
      className="main-menu"
    >
      {menuItems.map(item => (
        <Menu.Item key={item.path}>
          <NavLink to={item.url || item.path}>
            <span className="nav-text">{item.title}</span>
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default NavBar
