import React, { useEffect, useCallback, useState } from "react"
import { Button, Collapse } from "antd"
import { PropTypes } from "prop-types"

const { Panel } = Collapse

const RepeatedFormCollapse = ({
  form,
  initialValue,
  parentName,
  placeHolder,
  addBtnLabel,
  onChange,
  neverEmpty,
  title,
  children
}) => {
  const [keys, setKeys] = useState(neverEmpty ? [0] : [])
  const [initial, setInitial] = useState([])
  const [activeKey, setActiveKey] = useState("0")

  const resetInitialValue = useCallback(() => {
    if (initialValue[0] && !keys.length) {
      setKeys(Object.keys(initialValue).map(Number))
      setInitial(initialValue)
    }
  }, [initialValue, keys])

  useEffect(() => {
    resetInitialValue()
  }, [initialValue, resetInitialValue])

  useEffect(() => {
    onChange()
  }, [keys, onChange])

  const handlePanelChange = key => {
    if (isValid()) setActiveKey(key ? key.toString() : key)
  }

  const addFieldGroup = () => {
    let newKey = keys.length
    if (isValid() || !newKey) {
      while (keys.includes(newKey)) {
        newKey++
      }
      setKeys(keys.concat(newKey))
      setActiveKey(newKey.toString())
    }
  }

  const removeFieldGroup = key => {
    setKeys(keys.filter(k => k !== key))
    if (initial[key]) setInitial(initial.filter((_e, i) => i !== key))
  }

  const isValid = () => {
    if (!activeKey || !keys.length) return true

    let valid = false
    form.validateFields([`${parentName}[${activeKey}]`], err => {
      if (!err) {
        valid = true
      }
    })
    return valid
  }

  const content = keys.map(k => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form
    const header =
      getFieldValue(`${parentName}[${k}].name`) ||
      (initial[k] && initial[k].name) ||
      placeHolder
    return (
      <Panel
        header={header}
        key={k.toString()}
        forceRender
        extra={
          <Button
            disabled={neverEmpty && keys.length === 1}
            type="default"
            className="delete-button"
            icon="minus-circle-o"
            onClick={() => removeFieldGroup(k)}
          />
        }
      >
        {React.cloneElement(children, {
          getFieldDecorator,
          getFieldValue,
          setFieldsValue,
          initial: initial[k],
          parentName: `${parentName}[${k}]`
        })}
      </Panel>
    )
  })

  return (
    <div className="repeated-fields-wp">
      {title && title.length && (
        <div className="repeated-fields-title">{title}</div>
      )}
      <Collapse
        className="repeated-fields"
        accordion
        onChange={handlePanelChange}
        activeKey={activeKey}
      >
        {content}
      </Collapse>
      <Button
        onClick={addFieldGroup}
        className="add-btn"
        type="dashed"
        icon="plus"
      >
        {addBtnLabel}
      </Button>
    </div>
  )
}

RepeatedFormCollapse.defaultProps = {
  form: {},
  neverEmpty: false,
  onChange: () => {},
  initialValue: [],
  parentName: "",
  title: "",
  addBtnLabel: "Tilføj ny",
  placeHolder: "Ny"
}
RepeatedFormCollapse.propsType = {
  form: PropTypes.object.isRequired,
  neverEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  initialValue: PropTypes.array,
  parentName: PropTypes.string,
  title: PropTypes.string,
  addBtnLabel: PropTypes.string,
  placeHolder: PropTypes.string,
  children: PropTypes.element.isRequired
  /* children: function (props, propName, componentName) {
    const children = React.Children
    if (children.length !== 1)
      return new Error("`" + componentName + "` must only have one child.")

    if (
      children[0].type !== LocationItem ||
      children[0].type !== ExtContactItem
    )
      return new Error(
        "`" +
          componentName +
          "` children should be of type `LocationItem` or `ExtContactItem`."
      )
  } */
}

export default RepeatedFormCollapse
