import React, { Component } from "react"
import moment from "moment"
import { Select, Input, Empty, Row, DatePicker, Col } from "antd"
import { getEvents } from "../services/eventService"
import { getTicketApprovals } from "../services/ticketApprovalService"
import {
  ticketTypes,
  eventCategories,
  eventLocations
} from "../components/temp/eventsFakeData"
import EventCard from "../components/eventCard"
import LoadingSpin from "../components/common/loadingSpin"

const { Option } = Select
const { Search } = Input
const { RangePicker } = DatePicker

class Events extends Component {
  state = {
    events: [],
    searchQuery: "",
    pickedDate: null,
    ticketTypes: [],
    selectedType: undefined,
    eventCategories: [],
    selectedCategory: undefined,
    eventLocations: [],
    selectedLocation: undefined,
    eventApprovals: [],
    selectedApproval: undefined,
    eventSorts: [
      {
        id: 0,
        name: "ingen"
      },
      {
        id: 1,
        name: "Navn"
      },
      {
        id: 2,
        name: "Startdato"
      }
    ],
    selectedSort: undefined,
    loading: false
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: eventApprovals } = await getTicketApprovals()
    const { data: events } = await getEvents()
    global.log("Events List", events)
    this.setState({
      events,
      ticketTypes,
      eventCategories,
      eventLocations,
      eventApprovals,
      loading: false
    })
  }

  handleSearch = ({ currentTarget }) =>
    this.setState({ searchQuery: currentTarget.value })

  handleDatePick = value => this.setState({ pickedDate: value })

  handleTypeSelect = value =>
    this.setState({ selectedType: value === "all" ? undefined : value })

  handleCategorySelect = value =>
    this.setState({ selectedCategory: value === "all" ? undefined : value })

  handleLocaticonSelect = value =>
    this.setState({ selectedLocation: value === "all" ? undefined : value })

  handleApprovalSelect = value =>
    this.setState({ selectedApproval: value === "all" ? undefined : value })

  handleSortSelect = value =>
    this.setState({ selectedSort: value === 0 ? undefined : value })

  /*   formatDate = dateISO => {
    if (dateISO) {
      const dateObj = new Date(dateISO),
        date = dateObj.getDate(),
        month = dateObj.getMonth() + 1,
        year = dateObj.getFullYear()
      return date + "/" + month + "/" + year
    } else {
      return ""
    }
  };
 */
  getSearchResult = () => {
    const { events: allEvents, searchQuery, pickedDate } = this.state

    let filtered = allEvents

    if (searchQuery)
      filtered = allEvents.filter(e =>
        e.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      )
    else if (pickedDate && pickedDate.length > 0)
      filtered = allEvents.filter(
        e =>
          moment(e.dateStart).isSameOrAfter(pickedDate[0], "day") &&
          moment(e.dateStart).isSameOrBefore(pickedDate[1], "day")
      )

    return filtered
  }

  /*  eventImg = event => {
    return event.image && <img src={event.image.path} />
  }; */

  render() {
    const {
      searchQuery,
      loading,
      pickedDate,
      ticketTypes,
      eventCategories,
      eventLocations,
      eventSorts,
      selectedType,
      selectedCategory,
      selectedLocation,
      selectedApproval,
      eventApprovals,
      selectedSort
    } = this.state
    const grid = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 8,
      xl: 6
    }
    const events = this.getSearchResult()
    const mapEvents = events =>
      events.map(event => (
        <Col {...grid} key={event.id}>
          <EventCard event={event} />
        </Col>
      ))
    return loading ? (
      <LoadingSpin size="large" />
    ) : (
      <>
        <Row>
          <Col span={24}>
            <Search
              placeholder="Find Billetter"
              size="large"
              loading={loading}
              value={searchQuery}
              onChange={this.handleSearch}
              className="find-tickets-field"
            />
          </Col>
        </Row>
        <Row gutter={14} type="flex" className="adv-search">
          <Col className="adv-search-select">
            <Select
              placeholder="Billettype"
              value={selectedType}
              name="type"
              onChange={this.handleTypeSelect}
            >
              {ticketTypes.map(type => (
                <Option value={type.id} key={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="adv-search-select">
            <Select
              placeholder="Kategori"
              value={selectedCategory}
              onChange={this.handleCategorySelect}
            >
              {eventCategories.map(cat => (
                <Option value={cat.id} key={cat.id}>
                  {cat.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="adv-search-select">
            <Select
              value={selectedLocation}
              placeholder="Sted"
              onChange={this.handleLocaticonSelect}
            >
              {eventLocations.map(loc => (
                <Option value={loc.id} key={loc.id}>
                  {loc.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="adv-search-select">
            <Select
              value={selectedApproval}
              placeholder="Godkendelseshandling"
              onChange={this.handleApprovalSelect}
            >
              <Option value="all" key="all">
                Alle
              </Option>
              {eventApprovals.map(loc => (
                <Option value={loc.id} key={loc.id}>
                  {loc.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="adv-search-date">
            <RangePicker
              onChange={this.handleDatePick}
              value={pickedDate}
              placeholder={["Startdato", "Slutdato"]}
              format="DD/MM/YYYY"
            />
          </Col>
          <Col className="adv-search-select col-grow text-right">
            <Select
              value={selectedSort}
              placeholder="Sort"
              onChange={this.handleSortSelect}
            >
              {eventSorts.map(loc => (
                <Option value={loc.id} key={loc.id}>
                  {loc.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={14}>
          {Array.isArray(events) && events.length ? (
            mapEvents(events)
          ) : (
            <Col className="no-events">
              <Empty
                description="Din søgning gav ikke noget resultat.
                    Juster din søgning og prøv igen."
              />
            </Col>
          )}
        </Row>
      </>
    )
  }
}

export default Events
