import React, { useState } from "react"
import { Table, Button, Result, Badge, Row, Col } from "antd"
// import PageTitle from "../components/common/PageTitle"
import { formatDate } from "../utils/dateFunc"
import OrderForm from "../components/ordersForm"
import { orderListUrl } from "../services/ordersService"
import { useFetch } from "../utils/useFetch"

// const ButtonGroup = Button.Group

const Orders = ({ title }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ isLoading, isError, data }, dispatch] = useFetch(orderListUrl, {
    groups: []
  })
  const [activeTab, setActiveTab] = useState(0)
  const [expandedRow, setExpandedRow] = useState([])
  const expandedRowRender = ({ id }) => (
    <OrderForm setStatus={setStatus} orderId={id} />
  )

  const columns = [
    {
      title: "Event navn",
      dataIndex: "eventName",
      key: "eventName"
    },
    {
      title: "Event startdato",
      className: "text-right",
      dataIndex: "eventStartDate",
      key: "eventStartDate",
      render: text => formatDate(text)
    },
    /*     {
      title: "Oprettet af",
      dataIndex: "creator",
      key: "creator"
    }, */
    {
      title: "Oprettelse dato",
      className: "text-right",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => formatDate(text)
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: obj => (
        <Badge
          className="order-status-badge"
          status={obj.keyname}
          text={obj.title}
        />
      )
    }
  ]

  const setStatus = (id, status) => {
    const originalData = [...data].reduce((acc, e) => {
      acc.push(e.id === id ? { ...e, status } : e)
      return acc
    }, [])
    // global.log(originalData)
    dispatch({ type: "FETCH_SUCCESS", payload: originalData })
  }

  const onExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.id)
    }

    setExpandedRow(keys)
  }
  const onRow = record => {
    return {
      className: `${
        expandedRow.includes(record.id) ? "expand-parent" : ""
      } ${record.status && record.status.status} clickable`
    }
  }

  return (
    <>
      <h1>{title}</h1>
      <Row className="subtitle-buttons">
        <Col>
          {data &&
            data.groups &&
            data.groups.map((e, i) => (
              <Button
                type={i === activeTab ? "primary" : ""}
                onClick={() => setActiveTab(i)}
                key={e.id}
              >
                {e.name}
              </Button>
            ))}
        </Col>
      </Row>
      {isError ? (
        <Result
          status="warning"
          title="Der er opstået en teknisk fejl. Prøv igen senere."
        />
      ) : (
        <Table
          className="table-card orders"
          loading={isLoading}
          columns={columns}
          expandRowByClick
          rowKey="id"
          expandedRowRender={expandedRowRender}
          expandedRowKeys={expandedRow}
          dataSource={
            data && data.groups && data.groups[activeTab]
              ? data.groups[activeTab].orders
              : []
          }
          onRow={onRow}
          onExpand={onExpand}
          expandIconAsCell={false}
          expandIconColumnIndex={-1}
        />
      )}
    </>
  )
}

export default Orders
