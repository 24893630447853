import React from "react"
import { Row, Col, Card, Icon, Skeleton } from "antd"
import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"

const ContactCompanyPreview = ({ contact, loading }) => {
  const {
    name,
    id,
    vatNo,
    employeesQty,
    phone,
    email,
    streetName,
    streetNumber,
    city,
    zipCode,
    country,
    participantType
  } = contact
  const addressFirstLine = `${streetName} ${streetNumber}`
  const addressSecondLine = `${zipCode} ${city}${
    country ? `, ${country.name}` : ""
  }`
  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${addressFirstLine} ${addressSecondLine}`
  return (
    <Card className="card contact-card" title={name}>
      {!id ? (
        <>
          <Skeleton active={loading} paragraph />
          {!loading && (
            <div className="info-message pt-3">
              <Icon type="info-circle" theme="filled" /> Vælg en kontaktfirma,
              til at kunne se detaljer
            </div>
          )}
        </>
      ) : (
        <>
          <ul className="company-info">
            <li>
              <Row gutter={5} type="flex">
                <Col>
                  <span className="text-muted">Cvr nr.</span>
                </Col>
                <Col span={24}>
                  <span>{vatNo}</span>
                </Col>
              </Row>
            </li>
            <li>
              <Row gutter={5} type="flex">
                <Col>
                  <span className="text-muted">Type</span>
                </Col>
                <Col span={24}>
                  <span>{participantType && participantType.name}</span>
                </Col>
              </Row>
            </li>
            <li>
              <Row gutter={5} type="flex">
                <Col>
                  <span className="text-muted">Team</span>
                </Col>
                <Col span={24}>
                  <Link
                    to={`/contacts/${id}/list`}
                    replace
                  >{`${employeesQty} medarbejder`}</Link>
                </Col>
              </Row>
            </li>
          </ul>
          <ul className="contact-info">
            <li className="text-muted">Kontakt info</li>
            {phone && (
              <li>
                <a href={`tel:${phone}`}>
                  <Row gutter={5} type="flex">
                    <Col>
                      <Icon type="phone" />
                    </Col>
                    <Col>{phone}</Col>
                  </Row>
                </a>
              </li>
            )}
            {email && (
              <li>
                <a href={`mailto:${email}`}>
                  <Row gutter={5} type="flex">
                    <Col>
                      <Icon type="mail" />
                    </Col>
                    <Col>{email}</Col>
                  </Row>
                </a>
              </li>
            )}
            {streetName && (
              <li>
                <a href={addressUrl} target="_blank" rel="noreferrer noopener">
                  <Row gutter={5} type="flex">
                    <Col>
                      <Icon type="environment" />
                    </Col>
                    <Col>
                      {addressFirstLine}
                      <br />
                      {addressSecondLine}
                    </Col>
                  </Row>
                </a>
              </li>
            )}
          </ul>
        </>
      )}
    </Card>
  )
  /* ) : (
    <>
      <Card className="card contact-card">
      
      </Card>
    </>
  ) */
}

ContactCompanyPreview.defaultProps = {
  contact: {},
  loading: false
}

ContactCompanyPreview.propsType = {
  contact: PropTypes.object.isRequired,
  loading: PropTypes.bool
}

export default ContactCompanyPreview
