import React, { useState } from "react"
import { Input, Form, Select, Checkbox, Row, Col } from "antd"

const { TextArea } = Input
const { Option } = Select

const OrderEmployeeFields = ({ form, onQtyChange }) => {
  const { getFieldDecorator, getFieldValue } = form
  const ticketTypes = getFieldValue("ticketTypes")
  const isParticipating = getFieldValue("participateMyself")

  const [isParticipatingInit, setIsParticipatingInit] = useState(undefined)

  const handleIsParticipating = ({ target }) => {
    if (target.checked) {
      const companies = getFieldValue("companies") || []
      const employees = companies.map(item => item.participants || []).flat()
      const ticketTypeIds = employees.map(
        item => item.sponsorshipTicketEventPeriodId
      )
      // Get the most frequent value of ticket type Ids
      const counts = ticketTypeIds.reduce((acc, c) => {
        acc[c] = (acc[c] || 0) + 1
        return acc
      }, {})
      const maxCount = Math.max(...Object.values(counts))
      const mostFrequent = Object.keys(counts).filter(
        k => counts[k] === maxCount
      )
      setIsParticipatingInit(mostFrequent && mostFrequent[0])
    }
  }

  return (
    <Row className="order-employee-fields" type="flex">
      <Col sm={24} md={12}>
        <Form.Item>
          {getFieldDecorator("participateMyself", { valuePropName: "checked" })(
            <Checkbox onChange={handleIsParticipating}>
              Jeg skal også deltage
            </Checkbox>
          )}
        </Form.Item>
      </Col>
      <Col sm={24} md={12}>
        {isParticipating && (
          <Form.Item>
            {getFieldDecorator("sponsorshipTicketEventPeriodId", {
              initialValue: isParticipatingInit
            })(
              <Select onSelect={onQtyChange} placeholder="Billettype">
                {typeof ticketTypes !== "undefined" &&
                  ticketTypes.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                      {item.priceVisible && (
                        <small className="price-in-select">
                          {item.price ? `${item.price}DKK` : "Gratis"}
                        </small>
                      )}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Col>
      <Col span={24}>
        <Form.Item>
          {getFieldDecorator("message")(
            <TextArea
              placeholder="Besked til ledelse"
              autoSize={{
                minRows: 2,
                maxRows: 5
              }}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

export default OrderEmployeeFields
