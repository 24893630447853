import React from "react"
import { Redirect, Switch } from "react-router-dom"
import { homePath } from "../config.json"
import { routes } from "./appRoutes"

const Routes = () => {
  return (
    <Switch>
      {routes &&
        routes.map(route => (
          <route.route
            key={route.path}
            path={route.path}
            render={props => <route.component {...props} title={route.title} />}
          />
        ))}

      <Redirect from="/" exact to={homePath} />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
