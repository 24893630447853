import React from "react"
import { Form, Row, Col, Input, InputNumber, Select, Checkbox } from "antd"
import { PropTypes } from "prop-types"

const { Option } = Select

const ParticipantItem = ({
  getFieldDecorator,
  initial,
  parentName,
  getFieldValue,
  setFieldsValue,
  onQtyChange
}) => {
  const pn = parentName && parentName.length ? `${parentName}.` : ""
  const ticketTypes = getFieldValue("ticketTypes")
  const inviteReasons = getFieldValue("inviteReasons")

  const setPanelTitle = () => {
    let fullName = getFieldValue(`${pn}firstName`) || ""
    fullName = fullName ? `${fullName} ` : ""
    fullName += getFieldValue(`${pn}lastName`) || ""

    setFieldsValue({ [`${pn}name`]: fullName })
  }

  return (
    <div className="employee-expanded-row">
      <Row>
        <Col span={12}>
          {getFieldDecorator(`${pn}name`, {
            initialValue: initial && initial.name
          })(<Input type="hidden" />)}
          {getFieldDecorator(`${pn}id`, {
            initialValue: initial && initial.id
          })(<Input type="hidden" />)}
          <Form.Item>
            {getFieldDecorator(`${pn}firstName`, {
              initialValue: initial && initial.firstName,
              rules: [
                {
                  required: true,
                  message: "Påkrævet felt"
                }
              ]
            })(
              <Input
                autoFocus
                onBlur={setPanelTitle}
                placeholder="Fornavn"
                name="gstFn"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator(`${pn}lastName`, {
              initialValue: initial && initial.lastName,
              rules: [
                {
                  required: true,
                  message: "Påkrævet felt"
                }
              ]
            })(
              <Input
                onBlur={setPanelTitle}
                placeholder="Efternavn"
                name="gstLn"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator(`${pn}email`, {
              initialValue: initial && initial.email,
              rules: [
                {
                  type: "email",
                  message: "Ugyldig e-mail"
                },
                {
                  required: true,
                  message: "Påkrævet felt"
                }
              ]
            })(<Input placeholder="E-mail" name="gstEm" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator(`${pn}mobile`, {
              initialValue: initial && initial.mobile
            })(<Input placeholder="Mobil" name="gstMn" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator(`${pn}sponsorshipTicketEventPeriodId`, {
              initialValue:
                initial && initial.sponsorshipTicketEventPeriodId
                  ? initial.sponsorshipTicketEventPeriodId
                  : ticketTypes[0] && ticketTypes[0].id
            })(
              <Select onSelect={onQtyChange} placeholder="Billettype">
                {typeof ticketTypes !== "undefined" &&
                  ticketTypes.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                      {item.priceVisible && (
                        <small className="price-in-select">
                          {item.price ? `${item.price}DKK` : "Gratis"}
                        </small>
                      )}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator(`${pn}reasonId`, {
              rules: [
                {
                  required: true,
                  message: "Påkrævet felt"
                }
              ],
              initialValue:
                initial && initial.reasonId
                  ? initial.reasonId
                  : inviteReasons[0] && inviteReasons[0].id
            })(
              <Select placeholder="Årsag">
                {typeof inviteReasons !== "undefined" &&
                  inviteReasons.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.reason}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row className="group-order-row">
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator(`${pn}groupOrder`, {
              initialValue: initial && initial.groupOrder,
              valuePropName: "checked"
            })(<Checkbox onChange={onQtyChange}>Gruppebestilling</Checkbox>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          {getFieldValue(`${pn}groupOrder`) && (
            <Form.Item>
              {getFieldDecorator(`${pn}groupQty`, {
                initialValue: initial && initial.groupQty
              })(
                <InputNumber
                  onBlur={onQtyChange}
                  min={2}
                  max={100}
                  placeholder="Gruppe Antal"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  )
}

ParticipantItem.defaultProps = {
  parentName: "",
  initial: undefined,
  onQtyChange: () => {},
  getFieldValue: () => {},
  setFieldsValue: () => {},
  getFieldDecorator: () => {}
}
ParticipantItem.propsType = {
  parentName: PropTypes.string,
  initial: PropTypes.object,
  onQtyChange: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFieldsValue: PropTypes.func,
  getFieldDecorator: PropTypes.func.isRequired
}

export default ParticipantItem
