import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/storeContact`

function personUrl(id) {
  return `${apiEndpoint}/person/${id}`
}

function companyUrl(id) {
  return `${apiEndpoint}/company/${id}`
}

function personsByCompanyUrl(id) {
  return `${apiEndpoint}/personListByCompanyId?companyId=${id}`
}

function personsSearchByCompanyUrl(id, query) {
  return `${apiEndpoint}/personListByCompanyIdAndSearch?companyId=${id}&search=${query}`
}

export function getPersons() {
  return http.get(`${apiEndpoint}/personListLatest`)
}

export function personSearch(query) {
  return http.get(`${apiEndpoint}/personListBySearch?search=${query}`)
}

export function personsSearchByCompany(id, query) {
  return http.get(personsSearchByCompanyUrl(id, query))
}

export function getPerson(id) {
  return http.get(personUrl(id))
}

export function getCompanyContacts(id) {
  return http.get(personsByCompanyUrl(id))
}

export function getCompanies() {
  return http.get(`${apiEndpoint}/companyListLatest`)
}

export function companySearch(query) {
  return http.get(`${apiEndpoint}/companyListBySearch?search=${query}`)
}

export function getCompany(id) {
  return http.get(companyUrl(id))
}

// get contact persons
// // 1. latest
// // 2. by company id
// // 3. by search query

export function contactPersonList() {
  return [
    {
      id: 2,
      name: "Abed Alrahman Sayes",
      initials: "AS",
      firstName: "Abed Alrahman",
      lastName: "Sayes",
      email: "as@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 1,
      name: "Brian Staal",
      firstName: "Brian",
      lastName: "Staal",
      email: "bs@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 3,
      name: "Carlo De Silva",
      firstName: "Carlo",
      lastName: "De Silva",
      initials: "CDS",
      email: "cds@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 4,
      name: "Tom Walter",
      firstName: "Tom",
      lastName: "Walter",
      email: "tw@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 5,
      name: "Abed Alrahman Sayes",
      initials: "AS",
      firstName: "Abed Alrahman",
      lastName: "Sayes",
      email: "as@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 6,
      name: "Brian Staal",
      firstName: "Brian",
      lastName: "Staal",
      email: "bs@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 7,
      name: "Carlo De Silva",
      firstName: "Carlo",
      lastName: "De Silva",
      initials: "CDS",
      email: "cds@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 8,
      name: "Tom Walter",
      firstName: "Tom",
      lastName: "Walter",
      email: "tw@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    },
    {
      id: 9,
      name: "Abed Alrahman Sayes",
      initials: "AS",
      firstName: "Abed Alrahman",
      lastName: "Sayes",
      email: "as@onelounge.dk",
      mobile: "+45 45454545",
      company: { id: 2, name: "Onelounge IVS" }
    }
  ]
}

// get contact companies
// // 1. latest
// // 2. by search query

export function contactCompanyList() {
  return [
    {
      id: 1,
      name: "Onelounge IVS",
      employeesQty: 4,
      type: "Kunde",
      email: "cds@onelounge.dk",
      phone: "+45 45454545"
    },
    {
      id: 2,
      name: "Zerobit IVS",
      employeesQty: 2,
      type: "Kunde",
      email: "brian@zerobit.dk",
      phone: "+45 45454545"
    },
    {
      id: 3,
      name: "React JS",
      employeesQty: 16,
      type: "Lead",
      email: "react@react.dk",
      phone: "+45 45454545"
    },
    {
      id: 4,
      name: "Node JS",
      employeesQty: 16,
      type: "Kunde",
      email: "node@node.dk",
      phone: "+45 45454545"
    },
    {
      id: 5,
      name: "Ant Design",
      employeesQty: 16,
      type: "Kunde",
      email: "ant@antd.dk",
      phone: "+45 45454545"
    },
    {
      id: 6,
      name: "Photoshop",
      employeesQty: 16,
      type: "Partner",
      email: "ps@photoshop.dk",
      phone: "+45 45454545"
    },
    {
      id: 7,
      name: "Illustrator",
      employeesQty: 16,
      type: "Kunde",
      email: "vector@illustrator.dk",
      phone: "+45 45454545"
    },
    {
      id: 8,
      name: "InDesign",
      employeesQty: 16,
      type: "Kunde",
      email: "in@indesign.dk",
      phone: "+45 45454545"
    },
    {
      id: 9,
      name: "Adobe XD",
      employeesQty: 16,
      type: "Kunde",
      email: "xd@adobe.dk",
      phone: "+45 45454545"
    }
  ]
}

// get contact person by id

export function contactPerson() {
  return {
    id: 2,
    name: "Abed Alrahman Sayes",
    initials: "AS",
    firstName: "Abed Alrahman",
    lastName: "Sayes",
    email: "as@onelounge.dk",
    mobile: "+45 45454545",
    company: { id: 2, name: "Onelounge IVS" }
  }
}

// get contact company by id

export function contactCompany() {
  return {
    id: 2,
    name: "Zerobit IVS",
    vatNr: "38383838",
    employeesQty: 16,
    type: "Kunde",
    email: "as@onelounge.dk",
    phone: "+45 45454545",
    location: {
      id: 1,
      streetName: "Stadion Allé",
      streetNumber: 70,
      zipCode: "8000",
      city: "Aarhus",
      country: "Denmark",
      geoLocation: "56.131857, 10.193826"
    }
  }
}
