import moment from "moment"

export function getDate(date, time) {
  return time
    ? moment(`${date.format("YYYY-MM-DD")} ${time.format("HH:mm:ss")}`).format(
        "YYYY-MM-DDTHH:mm:ss"
      )
    : moment(`${date.format("YYYY-MM-DD")} ${"00:00:00"}`).format(
        "YYYY-MM-DDTHH:mm:ss"
      )
}

export function formatDate(date) {
  // moment.locale("da")
  return moment(date).format("DD-MM-YYYY")
}

export function formatTime(date) {
  return moment(date).format("HH:mm")
}
