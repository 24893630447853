import React from "react"
import { Avatar, Card, Row, Col, Icon, Skeleton } from "antd"
import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"
import { getInitials } from "../utils/getUserInitials"
import { stringToColor } from "../utils/stringToColor"

const ContactPersonPreview = ({ contact, loading }) => {
  const { name, initials, id, company, mobile, email } = contact
  const userColor = name && stringToColor(name)
  const userInitials = initials || (name && getInitials(name))
  return (
    <Card
      className="card contact-card"
      title={
        id && (
          <Row className="contact-card-header">
            <Col>
              <Avatar
                size="large"
                className="avatar-comp"
                style={{ backgroundColor: userColor }}
              >
                {userInitials}
              </Avatar>
            </Col>
            <Col className="col-grow">{name}</Col>
          </Row>
        )
      }
    >
      {!id ? (
        <>
          <Skeleton avatar active={loading} />
          {!loading && (
            <div className="info-message pt-3">
              <Icon type="info-circle" theme="filled" /> Vælg en kontaktperson,
              til at kunne se detaljer
            </div>
          )}
        </>
      ) : (
        <>
          <ul className="contact-info">
            <li className="text-muted">Kontakt info</li>
            {mobile && (
              <li>
                <a href={`tel:${mobile}`}>
                  <Row gutter={6} type="flex">
                    <Col>
                      <Icon type="mobile" />
                    </Col>
                    <Col>{mobile}</Col>
                  </Row>
                </a>
              </li>
            )}
            {email && (
              <li>
                <a href={`mailto:${email}`}>
                  <Row gutter={6} type="flex">
                    <Col>
                      <Icon type="mail" />
                    </Col>
                    <Col>{email}</Col>
                  </Row>
                </a>
              </li>
            )}
          </ul>
          <ul className="contact-info">
            <li className="text-muted">Firma</li>
            <li>
              <h4>{company.name}</h4>
            </li>
            <li>
              <Link to={`/contacts/${company.id}`} replace>
                <Row gutter={6} type="flex">
                  <Col>
                    <Icon type="link" />
                  </Col>
                  <Col>Kontakt info</Col>
                </Row>
              </Link>
            </li>
            <li>
              <Link to={`/contacts/${company.id}/list`} replace>
                <Row gutter={6} type="flex">
                  <Col>
                    <Icon type="team" />
                  </Col>
                  <Col>Kontaktpersoner</Col>
                </Row>
              </Link>
            </li>
          </ul>
        </>
      )}
    </Card>
  )
  /* ) : (
    <>
      <Card className="card contact-card">
        <Skeleton avatar />
        <div className="info-message pt-3">
          <Icon type="info-circle" theme="filled" /> Vælg en kontaktperson, til
          at kunne se detaljer
        </div>
      </Card>
    </>
  ) */
}

ContactPersonPreview.defaultProps = {
  contact: {},
  loading: false
}

ContactPersonPreview.propsType = {
  contact: PropTypes.object.isRequired,
  loading: PropTypes.bool
}

export default ContactPersonPreview
