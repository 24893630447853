import React from "react"
import { Row, Col, Icon } from "antd"

const CompanyPreview = ({ data, onSelect }) => {
  return Object.entries(data).length !== 0 && data.constructor === Object ? (
    <Row
      gutter={15}
      type="flex"
      className="company-preview-item"
      onClick={onSelect}
    >
      <div className="hovered-copmany">
        <Icon type="check-circle" />
      </div>
      <Col span={24}>
        <h3>
          {data.name} <span className="text-secondary">cvr. {data.vatNo}</span>
        </h3>
      </Col>
      {data.phone && (
        <Col>
          <span className="text-secondary">
            <Icon type="phone" />
          </span>
          <span>{data.phone}</span>
        </Col>
      )}
      {data.email && (
        <Col>
          <span className="text-secondary">
            <Icon type="mail" />
          </span>
          <span>{data.email}</span>
        </Col>
      )}
      {data.address && (
        <Col>
          <span className="text-secondary">
            <Icon type="environment" />
          </span>
          <span>{data.address}</span>
        </Col>
      )}
    </Row>
  ) : null
}

export default CompanyPreview
