import React from "react"
import { useEvents } from "../contexts/eventContext"

const Background = () => {
  const { background } = useEvents()
  return (
    background && (
      <div className="blured-bg-img">
        <img alt="" src={background} />
      </div>
    )
  )
}

export default Background
