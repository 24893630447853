import React from "react"
import { Result, Modal } from "antd"
import CountDown from "./common/countDown"

const OrderResult = ({ visible, onCancel, response }) => {
  return (
    <Modal
      visible={visible}
      okText="Tilbage"
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ hidden: true }}
      width={600}
    >
      <Result status="success" title="Tak, vi har nu modtaget din bestilling" />
      {response.urls && <CountDown response={response} />}
    </Modal>
  )
}

export default OrderResult
