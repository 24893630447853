import React from "react"
import { Button, Divider, Icon, Tag, Tabs, Row, Col } from "antd"
import { formatTime, formatDate } from "../utils/dateFunc"
import { newLineFormat } from "../utils/textFunc"

const { TabPane } = Tabs

const TicketContent = ({ showChildrenDrawer, event }) => {
  const { locationName: location } = event
  return (
    <div className="ticket-content-wp">
      <div className="event-info-wp">
        <div className="event-location d-flex align-center">
          {location && (
            <>
              <Icon type="environment" />
              <div className="col-grow">
                <strong>{location.name}</strong> <br />
                {`${location.streetName} ${location.streetNumber} - ${location.zipCode} ${location.city}`}
              </div>
            </>
          )}
          {event.responsables && event.responsables.length ? (
            <Button onClick={showChildrenDrawer}>Ansvarlig list</Button>
          ) : null}
        </div>
      </div>
      <div className="event-schedule-wp">
        {typeof event.dates !== "undefined" &&
          event.dates.map(date => (
            <Row key={date.id} type="flex" className="event-schedule-item">
              <Col className="col-grow event-date-time">
                <Icon type="calendar" />
                {`${formatDate(date.start)} - ${formatDate(date.end)}`}
                <span>
                  <Icon type="clock-circle" />
                  {`${formatTime(date.start)} - ${formatTime(date.end)}`}
                </span>
              </Col>
              <Col className="event-deadline">
                {`Frist ${formatDate(date.lastChance)} - ${formatTime(
                  date.lastChance
                )}`}
              </Col>
            </Row>
          ))}
      </div>

      <Tabs defaultActiveKey="1">
        {typeof event.tickets !== "undefined" &&
          event.tickets.length > 0 &&
          event.tickets.map(item => (
            <TabPane tab={item.name} key={item.id}>
              <p>{newLineFormat(item.description)}</p>
              <Row type="flex">
                <Col className="col-grow">
                  <h4 className="text-secondary">{item.ticketApproval}</h4>
                </Col>
                <Col>
                  <h4 className="text-secondary text-right">
                    {item.price} <small>DKK</small>
                  </h4>
                </Col>
              </Row>
            </TabPane>
          ))}
      </Tabs>
      <Divider />
      <div className="description">
        <h3>Eventbeskrivelse</h3>
        <p>{newLineFormat(event.description)}</p>
        {/* <Divider />
        <h3>Billettyper</h3> */}
        {/*  {typeof event.tickets !== "undefined" && event.tickets.length > 0 && (
          <ul className="ticket-description">
            {event.tickets.map((item, index) => (
              <li key={index}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </li>
            ))}
          </ul>
        )} */}
      </div>
      {typeof event.tags !== "undefined" && event.tags.length > 0 && (
        <div className="tags-wp">
          {event.tags.map(tag => (
            <Tag key={tag.key}>{tag.label}</Tag>
          ))}
        </div>
      )}
    </div>
  )
}

export default TicketContent
