import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/StoreOrder/ExcelImportExample`
const apiEndpointtest = `${oneLoungeApi}/StoreOrder/ExcelUploadParticipants`

export function getFileData() {
  return http.get(apiEndpoint)
}

export function getDataFromFile(file, withTitle) {
  const f = new FormData()
  f.append("postFile", file)
  f.append("firstRowIsColumnNames", withTitle)
  return http.post(apiEndpointtest, f, {
    headers: { "Content-Type": "multipart/form-data" }
  })
}
