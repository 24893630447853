import axios from "axios"
import { message } from "antd"
// import logger from "./logService"
import { logout, refreshJwt } from "./authService"

let isRefreshing = false

export function setJwt(jwt) {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`
}

axios.interceptors.response.use(null, async error => {
  const { response, config } = error
  const status = response && response.status

  if (status === 401 && response.statusText === "Invalid JWT Token") {
    if (!isRefreshing) {
      isRefreshing = true
      const originalRequest = { ...config }
      const { data, status } = await refreshJwt()
      if (status === 200 || status === 204) {
        isRefreshing = false
        originalRequest.headers.Authorization = `Bearer ${data.token}`
        return axios.request(originalRequest)
      }
      isRefreshing = false
      logout()
      window.location = "/"
    }
  } else if (status <= 400 && status > 500) {
    // logger.log(error)
    message.error("An unexpected error occurrred.")
  }

  return Promise.reject(error)
})

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
}
