import React from "react"
import { Spin } from "antd"

const LoadingSpin = ({ dehaze, ...rest }) => {
  return (
    <div className={`spin-wrapper ${dehaze ? "dehaze" : ""}`}>
      <Spin {...rest} />
    </div>
  )
}

export default LoadingSpin
