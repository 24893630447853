import React from "react"
import { Layout } from "antd"
import CacheBuster from "./cacheBuster"
// import context
import { EventsProvider } from "./contexts/eventContext"
// import layout elements
import Header from "./layout/header"
import Footer from "./layout/footer"
// import routes
import Routes from "./routes/routes"
// import main style file
import "./assets/scss/App.scss"

const App = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) refreshCacheAndReload()
        return (
          <Layout className="app-layout">
            <Header />
            <Layout.Content id="content-wrapper">
              <EventsProvider>
                <main className="container main">
                  <Routes />
                </main>
              </EventsProvider>
            </Layout.Content>
            <Footer />
          </Layout>
        )
      }}
    </CacheBuster>
  )
}

export default App
