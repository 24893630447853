export const ticketTypes = [
  {
    id: "all",
    name: "Alle"
  },
  {
    id: 0,
    name: "VIP"
  },
  {
    id: 1,
    name: "Standard"
  }
]
export const eventCategories = [
  {
    id: "all",
    name: "Alle"
  },
  {
    id: 0,
    name: "Royal Arena"
  },
  {
    id: 1,
    name: "Basketball"
  },
  {
    id: 2,
    name: "Håndbold"
  },
  {
    id: 3,
    name: "Superliga"
  },
  {
    id: 4,
    name: "Boxen"
  }
]
export const eventLocations = [
  {
    id: "all",
    name: "Alle"
  },
  {
    id: 0,
    name: "Sjælland"
  },
  {
    id: 1,
    name: "Syddanmark"
  },
  {
    id: 2,
    name: "Nordjylland"
  },
  {
    id: 3,
    name: "Midtjylland"
  }
]
