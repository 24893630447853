import React from "react"
import { Row, Col, Button, Icon } from "antd"

const ListHeader = ({
  searchMode,
  onCancel,
  searchQuery,
  contactType,
  filterBy
}) => {
  const filteredTitle =
    filterBy && filterBy.name ? `${filterBy.name} kontaktpersoner : ` : ""
  const searchTitle = `resultater for: ${searchQuery}`
  const title =
    contactType === "person"
      ? "Nyeste kontaktpersoner"
      : "Nyeste kontaktfirmaer"
  return (
    <Row type="flex" align="middle" className="list-header">
      {searchMode ? (
        <>
          <Col>
            {" "}
            <Button type="link" onClick={onCancel} className="mr-2">
              <Icon type="close" />
            </Button>
          </Col>
          <Col>
            <h3 className="text-muted mb-0">{filteredTitle + searchTitle}</h3>
          </Col>
        </>
      ) : (
        <Col>
          <h3 className="text-muted mb-0">{filteredTitle || title}</h3>
        </Col>
      )}
    </Row>
  )
}

export default ListHeader
