import React from "react"
import { Button, Card, Popconfirm } from "antd"
import { PropTypes } from "prop-types"

const RepeatedCard = ({
  form,
  keysFieldName,
  initialFieldName,
  parentName,
  placeHolder,
  addBtnLabel,
  disableAdd,
  title,
  neverEmpty,
  //  onChange,
  children
}) => {
  const { getFieldValue, setFieldsValue } = form

  const initial = getFieldValue(initialFieldName)
  const keys = getFieldValue(keysFieldName)

  /* useEffect(() => {
    global.log(keys)
    //    onChange()
  }, [keys]) */

  const add = () => {
    const keys = getFieldValue("companiesKeys")
    const newKey = keys.length === 0 ? 0 : keys[keys.length - 1] + 1

    setFieldsValue({ keysFieldName: keys.concat(newKey) })
  }

  const remove = key => {
    const keys = getFieldValue(keysFieldName)
    const initial = getFieldValue(initialFieldName)

    setFieldsValue({ [keysFieldName]: keys.filter(k => k !== key) })
    if (initial[key]) {
      delete initial[key]
      setFieldsValue({ [initialFieldName]: initial })
    }
  }

  return (
    <div className="repeated-card-wp">
      {title && title.length && (
        <div className="repeated-fields-title">{title}</div>
      )}
      {keys.map(k => {
        const header =
          getFieldValue(`${parentName}[${k}].name`) ||
          (initial[k] && initial[k].name) ||
          placeHolder
        return (
          <Card
            key={`${k}wrapper`}
            type="inner"
            className="participant-company-card"
            title={header}
            extra={
              <Popconfirm
                title="Vil du slette deltagen firma og alle tilføjet medarbejder?"
                onConfirm={() => remove(k)}
                okText="Ja"
                cancelText="Nej"
              >
                <Button
                  shape="circle"
                  icon="delete"
                  disabled={neverEmpty && keys.length === 1}
                  className="delete-button"
                />
              </Popconfirm>
            }
          >
            {React.cloneElement(children, {
              form,
              initial: initial[k],
              parentName: `${parentName}[${k}]`
            })}
          </Card>
        )
      })}
      {!disableAdd && (
        <Button onClick={add} className="add-btn" type="dashed" icon="plus">
          {addBtnLabel}
        </Button>
      )}
    </div>
  )
}

RepeatedCard.defaultProps = {
  form: {},
  neverEmpty: false,
  disableAdd: false,
  placeHolder: "Ny",
  keysFieldName: "keys",
  initialFieldName: "initials",
  parentName: "",
  onChange: () => {},
  addBtnLabel: "Tilføj ny",
  title: ""
}
RepeatedCard.propsType = {
  form: PropTypes.object.isRequired,
  parentName: PropTypes.string,
  keysFieldName: PropTypes.string,
  initialFieldName: PropTypes.string,
  neverEmpty: PropTypes.bool,
  disableAdd: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  addBtnLabel: PropTypes.string,
  placeHolder: PropTypes.string,
  children: PropTypes.element.isRequired
  /* children: function (props, propName, componentName) {
    const children = React.Children
    if (children.length !== 1)
      return new Error("`" + componentName + "` must only have one child.")

    if (
      children[0].type !== LocationItem ||
      children[0].type !== ExtContactItem
    )
      return new Error(
        "`" +
          componentName +
          "` children should be of type `LocationItem` or `ExtContactItem`."
      )
  } */
}

export default RepeatedCard
