import React from "react"
import { Row, Col } from "antd"

const TotalTickets = ({ ticketsQty, ticketsTotalPrice }) => {
  return (
    <div className="handling-and-book">
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        className="tickets-total-antal"
      >
        <Col>Valgte billetter:</Col>
        <Col className="text-bold">{ticketsQty} stk.</Col>
      </Row>
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        className="tickets-total-price"
      >
        <Col>Total pris</Col>
        <Col className="text-bold">DKK {ticketsTotalPrice}</Col>
      </Row>
      {/*
      <div className="handling-message">
        <Icon type="info-circle" theme="filled" /> Billetter på den event skal
        selvhenters
      </div> */}
    </div>
  )
}

export default TotalTickets
