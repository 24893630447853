import React, { useState } from "react"
import { Button, Carousel, Typography, Icon, Divider, Drawer } from "antd"
import { useHistory } from "react-router-dom"
import TicketContent from "./ticketTypeTabContent"
import { formatDate } from "../utils/dateFunc"
import LoadingSpin from "./common/loadingSpin"
import {
  useEvents,
  useEventsActions,
  useDrawer
} from "../contexts/eventContext"

const EventDrawer = () => {
  const [childrenDrawerVisiable, setChildrenDrawerVisible] = useState(false)
  const history = useHistory()

  const eventActions = useEventsActions()
  const eventState = useEvents()
  const drawer = useDrawer()
  const { isLoading, error, event } = eventState
  const { disableOrder, visible } = drawer

  const handleOrder = () => {
    if (event) {
      const { id, sponsorshipId } = event
      eventActions.closeDrawer()
      history.push(`/order-tickets/${id}/${sponsorshipId}`)
    }
  }

  const afterVisibleChange = visible => {
    if (visible) document.body.style.width = "100%"
  }

  return (
    <Drawer
      width={600}
      onClose={() => eventActions.closeDrawer()}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
      className="popup-event"
    >
      {isLoading && <LoadingSpin dehaze />}
      {event && !error && (
        <div className="event-drawer-wrapper">
          <div className="event-cover-img">
            <Carousel>
              {event.images &&
                event.images.map(slide => (
                  <div key={slide.id}>
                    <img src={slide.path} alt={slide.name} />
                  </div>
                ))}
              {event.image && (
                <img src={event.image.path} alt={event.image.name} />
              )}
            </Carousel>
          </div>
          <div className="event-popup-title d-flex">
            <div className="col-grow">
              <div className="event-title">
                <Typography.Title level={4}>{event.name}</Typography.Title>
              </div>
              <div className="event-subtitle">{event.categoryName}</div>
            </div>
            <div className="event-main-deadline">
              Udløber <br /> {formatDate(event.dateExpire)}
            </div>
          </div>

          <TicketContent
            showChildrenDrawer={() => setChildrenDrawerVisible(true)}
            event={event}
          />
          {event.responsables && (
            <Drawer
              title="Ansvarlig List"
              width={320}
              closable={false}
              onClose={() => setChildrenDrawerVisible(false)}
              visible={childrenDrawerVisiable}
              className="inner-drawer"
            >
              {event.responsables.map(item => (
                <div className="responsable-item" key={item.id}>
                  <h4 className="responsibility">{item.responsibility}</h4>
                  <h3>{item.name}</h3>
                  <Divider dashed />
                  {item.phone && (
                    <a
                      href={`tel:${item.phone}`}
                      className="responsable-detail"
                    >
                      <Icon type="phone" />
                      {item.phone}
                    </a>
                  )}
                  {item.email && (
                    <a
                      href={`mailto:${item.email}`}
                      className="responsable-detail"
                    >
                      <Icon type="mail" />
                      {item.email}
                    </a>
                  )}
                </div>
              ))}
            </Drawer>
          )}
        </div>
      )}
      {!disableOrder && (
        <div className="event-drawer-footer d-flex align-center">
          <div className="col-grow text-left">Er du interesseret?</div>
          {/* <Button icon="save" style={{marginRight: 8}} onClick={onClose}>
              Gem
            </Button> */}
          <Button onClick={handleOrder} type="primary">
            Videre
          </Button>
        </div>
      )}
    </Drawer>
  )
}

export default EventDrawer
