import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/StoreOrder`

export function getInviteReasons() {
  return http.get(`${apiEndpoint}/GetInviteReasons`)
}

export function placeOrder(order) {
  return http.post(`${apiEndpoint}/PlaceOrder`, order)
}
