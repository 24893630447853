import React, {
  useState,
  useMemo,
  createContext,
  useContext,
  useCallback
} from "react"
import { getEvent } from "../services/eventService"
import EventDrawer from "../components/eventDrawer"
import Background from "../components/background"

const EventsContext = createContext()
const DrawerContext = createContext()
const EventsActionsContext = createContext()

const stateInitials = {
  isLoading: false,
  error: null,
  event: null,
  background: ""
}
const drawerInitials = {
  disableOrder: false,
  visible: false
}

function EventsProvider({ children }) {
  const [state, setState] = useState(stateInitials)
  const [drawer, setDrawer] = useState(drawerInitials)
  const [cache, setCache] = useState([])

  const isCached = useCallback(
    payload => {
      if (cache.length && payload.id && payload.sponsorshipId) {
        return cache.find(e => e.id === payload.id)
      }
      return null
    },
    [cache]
  )

  const actions = useMemo(
    () => ({
      openDrawer: (event, disableOrder = false) => {
        setDrawer(drawer => ({
          ...drawer,
          visible: true,
          disableOrder
        }))
        actions.getEvent(event)
      },
      closeDrawer: () => {
        setDrawer(drawer => ({
          ...drawer,
          event: null,
          disableOrder: false,
          visible: false
        }))
      },
      setBackground: path => {
        setState(state => ({
          ...state,
          background: path
        }))
      },
      getEvent: async payload => {
        setState(state => ({
          ...state,
          event: payload,
          isLoading: true
        }))
        const cashedEvent = isCached(payload)
        if (cashedEvent) {
          global.log("cashed >>>", cashedEvent)
          setState(state => ({
            ...state,
            event: cashedEvent,
            isLoading: false
          }))
        } else {
          try {
            const { data: event } = await getEvent(
              payload.id,
              payload.sponsorshipId
            )
            setState(state => ({
              ...state,
              event,
              isLoading: false
            }))
            setCache(cache => [...cache, event])
          } catch (error) {
            setState(state => ({
              ...state,
              error,
              isLoading: false
            }))
          }
        }
      }
    }),
    [isCached]
  )
  return (
    <EventsContext.Provider value={state}>
      <DrawerContext.Provider value={drawer}>
        <EventsActionsContext.Provider value={actions}>
          <EventDrawer />
          <Background />
          {children}
        </EventsActionsContext.Provider>
      </DrawerContext.Provider>
    </EventsContext.Provider>
  )
}
function useEvents() {
  const context = useContext(EventsContext)
  if (context === undefined) {
    throw new Error("useEvents must be used within a EventsProvider")
  }
  return context
}
function useDrawer() {
  const context = useContext(DrawerContext)
  if (context === undefined) {
    throw new Error("useDrawer must be used within a EventsProvider")
  }
  return context
}
function useEventsActions() {
  const context = useContext(EventsActionsContext)
  if (context === undefined) {
    throw new Error("useEventsActions must be used within a EventsProvider")
  }
  return context
}
export { EventsProvider, useEventsActions, useEvents, useDrawer }
