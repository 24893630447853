import React from "react"
import { Button, Input, Select, Icon } from "antd"

const { Option } = Select
const { Search } = Input

const ContactsSearch = ({
  onSearch,
  searchValue,
  onTypeSelect,
  type,
  loading,
  onChange,
  filterBy,
  clearFilter
}) => {
  const filtered = filterBy && (
    <Button type="link" onClick={clearFilter} title={filterBy.name}>
      <Icon className="show-on-hover" type="close" />
      <Icon className="hide-on-hover" type="filter" />
      {filterBy.name}
    </Button>
  )

  return (
    <Search
      className="contact-search"
      placeholder="Søg her.."
      onSearch={onSearch}
      value={searchValue}
      onChange={onChange}
      disabled={loading}
      addonAfter={filtered}
      addonBefore={
        <Select
          value={type}
          disabled={loading}
          onSelect={onTypeSelect}
          className="contact-search-select"
        >
          <Option value="person">Personer</Option>
          <Option value="company">Firmaer</Option>
        </Select>
      }
    />
  )
}

export default ContactsSearch
