import React, { useEffect } from "react"
import { Card, Typography } from "antd"
import { useParams } from "react-router-dom"
import { useEvents, useEventsActions } from "../contexts/eventContext"
import Img from "./common/img"
import LoadingCard from "./common/loadingCard"

const EventPreview = () => {
  const eventState = useEvents()
  const { isLoading, error, event } = eventState
  const eventActions = useEventsActions()
  const { id, sid } = useParams()

  useEffect(() => {
    if (id && sid) {
      const event = {
        id: Number(id),
        sponsorshipId: Number(sid)
      }
      eventActions.getEvent(event)
    }
  }, [eventActions, id, sid])

  useEffect(() => {
    if (event && event.images && event.images.length)
      eventActions.setBackground(event.images[0].path)
    return () => {
      eventActions.setBackground("")
    }
  }, [event, eventActions])

  return (
    <>
      {isLoading && <LoadingCard img />}
      {!isLoading && event && !error && (
        <Card
          className="event-preview"
          onClick={() => eventActions.openDrawer(event, true)}
          cover={
            <Img
              src={event.images && event.images[0] && event.images[0].path}
              alt={event.images && event.images[0] && event.images[0].name}
            />
          }
        >
          <div className="event-title">
            <Typography.Title level={4} ellipsis>
              {event.name}
            </Typography.Title>
          </div>
          <div className="event-subtitle">{event.categoryName}</div>
        </Card>
      )}
    </>
  )
}

export default EventPreview
