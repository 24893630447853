import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/StoreOrder/GetTicketTypes`

function ticketTypeUrl(id, sid) {
  return `${apiEndpoint}?sponsorshipId=${sid}&eventId=${id}`
}

export function getEventTicketType(id, sid) {
  return http.get(ticketTypeUrl(id, sid))
}
