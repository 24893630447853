import React from "react"
import { List, Empty } from "antd"
import CompanyListItem from "./companyListItem"

const CompanyList = ({ list, loading, selected }) => {
  const paginationProps = {
    hideOnSinglePage: true,
    disabled: loading,
    pageSize: 8
  }

  return list.length ? (
    <List
      className="contacts-list"
      loading={loading}
      itemLayout="horizontal"
      pagination={paginationProps}
      dataSource={list}
      split={false}
      rowKey="id"
      renderItem={contact => (
        <CompanyListItem selected={selected} contact={contact} />
      )}
    />
  ) : (
    <Empty
      className="mt-5"
      description="Vi har masser kontakter, søg til at find den ønskede kontakt."
    />
  )
}

export default CompanyList
