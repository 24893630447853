import React, { Fragment } from "react"

export const newLineFormat = text =>
  text
    ? text.split("\n").map((item, key) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        )
      })
    : text
