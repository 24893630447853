import React, { Component } from "react"
import { Form, Spin, Row, Switch, Result, Col } from "antd"
import { getOrder /* saveOrder */ } from "../services/ordersService"
import OrderParticipantsCompany from "./orderParticipantsCompany"
import ChatWindow from "./chatWindow"
import OrderTotal from "./orderTotal"

class OrderForm extends Component {
  state = {
    loading: true,
    error: null,
    order: {}
  }

  async componentDidMount() {
    try {
      const { data: order } = await getOrder(this.props.orderId)
      this.setState({
        order,
        loading: false
      })
    } catch (error) {
      global.log(error)
      this.setState({
        error: error.response,
        loading: false
      })
    }
  }

  /*   handleConditionalApproval = () => {
    const { getFieldsValue } = this.props.form
    getFieldsValue((err, values) => {
      if (!err) {
        global.log(values)
      }
    })
  } */

  handleApproval = async approved => {
    // global.log(`?orderId=${this.props.orderId}`, { approved })
    const order = { ...this.state.order }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const newOrder = { ...values }
        newOrder.approved = approved
        newOrder.id = order.id
        global.log(newOrder)
      }
    })
    this.props.setStatus(1, {
      keyname: "success",
      title: "Accepteret"
    })
    /*     try {
      const response = await saveOrder(order)
      global.log(response)
    } catch (error) {
      global.log(error.response)
    } */
  }

  handleSwitch = () => {
    /* const { validateFields } = this.props.form
    const order = { ...this.state.order }
    validateFields((err, values) => {
      if (!err) {
        const initCounter = [...order.totalTickets]
        initCounter.forEach(e => {
          e.quantity = 0
        })

        order.totalTickets = values.companies
          .map(item => item.participants || [])
          .concat(order.employee.ticket || [])
          .flat()
          .reduce((acc, e) => {
            acc.find(x => x.id === e.ticket.id).quantity += e.isParticipating
              ? e.ticket.quantity
              : 0
            return acc
          }, initCounter)

        this.setState({
          order
        })
      }
    }) */
    global.log("calculate")
  }

  render() {
    const { order, error, loading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <div className="order-details-wp">
        {error && (
          <Result
            status="warning"
            title={
              error.message
                ? error.message
                : "Der er opstået en teknisk fejl. Prøv igen senere."
            }
          />
        )}
        {loading && <Spin />}
        {order.id && (
          <Form colon={false}>
            <OrderParticipantsCompany
              onSwitch={this.handleSwitch}
              participantsCompany={order.companies}
              getFieldDecorator={getFieldDecorator}
            />
            <ChatWindow
              initialMessages={order.messages}
              employee={order.employee}
              orderId={order.id}
              exstra={
                <Row type="flex" align="middle">
                  {order.employee.ticket ? (
                    <>
                      <Col className="order-participant-ticket">
                        {order.employee.ticket.name}
                      </Col>
                      <Col className="user-switch">
                        {getFieldDecorator(`employee.isParticipating`, {
                          initialValue: order.employee.isParticipating,
                          valuePropName: "checked"
                        })(
                          <Switch
                            disabled
                            onClick={this.handleSwitch}
                            size="small"
                          />
                        )}
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              }
            />
            <OrderTotal order={order} />
            {/* <Row type="flex" className="order-btns-wp">
              <Col>
                <Button
                  type="danger"
                  onClick={() => this.handleApproval(false)}
                  ghost
                >
                  Afvis
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.handleApproval(true)}
                >
                  Accepter
                </Button>
              </Col>
            </Row> */}
          </Form>
        )}
      </div>
    )
  }
}

export default Form.create()(OrderForm)
