import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/StoreEvent`

export function eventUrl(id, sid) {
  return `${apiEndpoint}/Detail?id=${id}&sid=${sid}`
}

export function getEvents() {
  return http.get(`${apiEndpoint}/List`)
}

export function getEvent(eventId, sponsorId) {
  return http.get(eventUrl(eventId, sponsorId))
}

export function saveEvent(event) {
  if (event.id !== 0) return http.put(apiEndpoint, event)

  return http.post(apiEndpoint, event)
}

export function deleteEvent(eventId) {
  return http.delete(eventUrl(eventId))
}
