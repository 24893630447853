import React from "react"
import { Result, Icon } from "antd"

const Contacts = ({ title }) => {
  return (
    <>
      <h1>{title}</h1>
      <Result
        icon={<Icon type="experiment" theme="twoTone" />}
        title="Denne side er under opbygning, prøve igen senere"
      />
    </>
  )
}

export default Contacts
