import React, { useEffect, useReducer } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Row, Col, Affix } from "antd"
import { reducer, initialState } from "./contactReducer"
import ContactPersonPreview from "../components/contactPersonPerview"
import ContactCompanyPreview from "../components/contactCompanyPerview"
import PersonList from "../components/personList"
import CompanyList from "../components/companyList"
import ContactsSearch from "../components/contctsSearch"
import ListHeader from "../components/listHeader"
import {
  getPerson,
  getPersons,
  personSearch,
  personsSearchByCompany,
  getCompanyContacts,
  getCompanies,
  companySearch,
  getCompany
} from "../services/contactsService"

const Contacts = ({ title }) => {
  const history = useHistory()
  const { companyId, contactId } = useParams()
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    contactType,
    persons,
    companies,
    selectedPerson,
    selectedCompany,
    isLoading,
    isErrorList,
    isErrorContact,
    listTitle,
    searchQuery,
    reset,
    perservedQuery,
    filterBy,
    searchMode
  } = state

  useEffect(() => {
    async function fetchData() {
      switch (true) {
        case companyId && !contactId && contactType !== "company": {
          // make sure company type is selected..
          dispatch({ type: "TypeChange", payload: "company" })
          break
        }
        case companyId && !contactId && contactType === "company": {
          // A company is selected, do something about it!
          // let's fetch that company:
          try {
            dispatch({ type: "FetchingData" })
            const { data: payload } = await getCompany(companyId)
            dispatch({ type: "CompanySelected", payload })
          } catch (error) {
            dispatch({ type: "FetchingContactFailed", payload: error })
          }
          break
        }
        case companyId && contactId === "list": {
          // A company is selected & list employees button is clicked!
          // let's fetch that company employees:
          try {
            dispatch({ type: "FetchingData" })
            const { data: payload } = await getCompanyContacts(companyId)
            global.log(payload)
            dispatch({
              type: "ListFilteredPersons",
              payload
            })
          } catch (error) {
            dispatch({ type: "FetchingContactFailed", payload: error })
          }
          break
        }
        case companyId && contactId && contactType !== "person": {
          // make sure company type is selected..
          dispatch({ type: "TypeChange", payload: "perosn" })
          break
        }
        case companyId && contactId && contactType === "person": {
          // A person is selected, do something about it!
          // let's fetch that person:
          try {
            dispatch({ type: "FetchingData" })
            const { data: payload } = await getPerson(contactId)
            dispatch({ type: "PersonSelected", payload })
          } catch (error) {
            // Oh oh! Got an error
            dispatch({ type: "FetchingContactFailed", payload: error })
          }
          break
        }
        case !companyId && !contactId && contactType === "company": {
          // Company type is selected, do something about it!
          // let's fetch latest contactCompanies:
          try {
            dispatch({ type: "FetchingData" })
            const { data: payload } = await getCompanies()
            global.log(payload)
            dispatch({ type: "ListCompanies", payload })
          } catch (error) {
            dispatch({ type: "FetchingListFailed", payload: error })
          }
          break
        }
        case !companyId && !contactId && contactType === "person": {
          // Person type is selected, do something about it!
          // let's fetch latest contactPersons:
          try {
            dispatch({ type: "FetchingData" })
            const { data: payload } = await getPersons()
            dispatch({ type: "ListPersons", payload })
          } catch (error) {
            dispatch({ type: "FetchingListFailed", payload: error })
          }
          break
        }
        default:
          global.log("Whaat! how is it possible! no Ids and no selected type")
      }
    }
    fetchData()
  }, [companyId, contactId, contactType, reset])

  const handleSearch = async value => {
    if (value.trim() === "") return
    if (contactType === "person") {
      if (filterBy && filterBy.id) {
        try {
          dispatch({ type: "FetchingData" })
          const { data: payload } = await personsSearchByCompany(
            filterBy.id,
            value
          )
          dispatch({ type: "ListPersonsResults", payload })
        } catch (error) {
          dispatch({ type: "FetchingListFailed", payload: error })
        }
      } else {
        try {
          dispatch({ type: "FetchingData" })
          const { data: payload } = await personSearch(value)
          dispatch({ type: "ListPersonsResults", payload })
        } catch (error) {
          dispatch({ type: "FetchingListFailed", payload: error })
        }
      }
    } else {
      try {
        dispatch({ type: "FetchingData" })
        const { data: payload } = await companySearch(value)
        dispatch({ type: "ListCompaniesResults", payload })
      } catch (error) {
        dispatch({ type: "FetchingListFailed", payload: error })
      }
    }
  }
  const handleSearchChange = ({ target }) => {
    dispatch({ type: "SearchQuery", payload: target.value })
  }
  const handleTypeChange = payload => {
    dispatch({ type: "TypeChange", payload })
    history.replace(`/contacts`)
  }
  const handleFilterReset = () => {
    dispatch({ type: "ResetFilter" })
    history.replace(`/contacts`)
  }
  const handleSearchReset = () => {
    history.replace(
      filterBy && filterBy.id ? `/contacts/${filterBy.id}/list` : `/contacts`
    )
    dispatch({ type: "ResetSearch" })
  }

  return (
    <>
      <h1>{title}</h1>
      <Row gutter={15}>
        <Col span={16}>
          <ContactsSearch
            type={contactType}
            searchValue={searchQuery}
            onSearch={handleSearch}
            onTypeSelect={handleTypeChange}
            onChange={handleSearchChange}
            loading={isLoading}
            filterBy={filterBy}
            clearFilter={handleFilterReset}
          />
          {isErrorList && "Kunne ikke hente Kontakter.."}
          <ListHeader
            searchQuery={perservedQuery}
            contactType={contactType}
            searchMode={searchMode}
            onCancel={handleSearchReset}
            filterBy={filterBy}
          />
          {contactType === "person" ? (
            <PersonList
              listTitle={listTitle}
              list={persons}
              selected={selectedPerson.id}
              loading={isLoading}
              isSearchResults={searchMode}
              reset={handleSearchReset}
            />
          ) : (
            <CompanyList
              listTitle={listTitle}
              list={companies}
              selected={selectedCompany.id}
              loading={isLoading}
              isSearchResults={searchMode}
              reset={handleSearchReset}
            />
          )}
        </Col>
        <Col span={8}>
          <Affix offsetTop={15}>
            <>
              {isErrorContact && "Kunne ikke hente Kontakter"}
              {contactType === "person" ? (
                <ContactPersonPreview
                  contact={selectedPerson}
                  loading={isLoading}
                />
              ) : (
                <ContactCompanyPreview
                  contact={selectedCompany}
                  loading={isLoading}
                />
              )}
            </>
          </Affix>
        </Col>
      </Row>
    </>
  )
}

export default Contacts
