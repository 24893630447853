/* eslint-disable jsx-a11y/alt-text */
import React from "react"

const Img = ({ src, ...rest }) => {
  return (
    <img
      src={
        src ||
        "https://icon-library.net/images/no-image-available-icon/no-image-available-icon-6.jpg"
      }
      {...rest}
    />
  )
}

export default Img
