export function sumQty(array, key = "quantity") {
  // eslint-disable-next-line radix
  return array.reduce((a, b) => a + parseInt(b[key] || 0), 0)
}
export function sumPrice(tickets, ticketTypes) {
  let totalPrice = 0
  for (let i = 0; i < tickets.length; i++) {
    const priceObj = ticketTypes.filter(obj => obj.id === tickets[i].typeId)
    global.log(priceObj[0].price)
    // eslint-disable-next-line radix
    totalPrice += parseInt(tickets[i].quantity || 0) * priceObj[0].price
  }
  return totalPrice
}

export function sumParticipants(participants) {
  return participants.length > 0
    ? participants.reduce((total, item) => {
        return item.groupOrder && item.groupQty
          ? total + item.groupQty
          : total + 1
      }, 0)
    : 0
}

export function sumTotalPrice(participants, ticketTypes) {
  return participants.length > 0
    ? participants.reduce((total, item) => {
        const index = ticketTypes.findIndex(t => t.id === item.ticketType)
        const price =
          typeof ticketTypes[index] !== "undefined"
            ? ticketTypes[index].price
            : ticketTypes[0].price
        return item.groupOrder && item.groupQty
          ? total + item.groupQty * price
          : total + price
      }, 0)
    : 0
}
