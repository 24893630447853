import React, { useState } from "react"
import { Form, Input, Empty } from "antd"
import CompanyPreview from "./companyPreview"
import { fetchCompany } from "../services/cvrService"

const { Search } = Input

const ParticipantSearch = ({ onCompanySelect }) => {
  const [query, setQuery] = useState("")
  const [error, setError] = useState(null)
  const [results, setResults] = useState({})
  const onSelect = () => {
    onCompanySelect(results)
    setResults({})
  }
  const handleSearch = async value => {
    if (value.trim()) {
      try {
        setQuery(value)
        setResults({})
        setError(null)
        const { data } = await fetchCompany(value)
        global.log(data)
        setResults(data)
      } catch (err) {
        setError(err)
      }
    }
  }

  const formItemLayout = { colon: false }
  return (
    <div className="add-company">
      <Form.Item {...formItemLayout} label="Tilføj firma">
        <Search
          size="large"
          placeholder="Søg efter CVR nr. eller firmanavnet"
          onSearch={handleSearch}
        />
      </Form.Item>
      {error && (
        <Empty
          description={`Vi fandt desværre ikke noget firma ved søgning på "${query}"`}
        />
      )}
      <CompanyPreview onSelect={onSelect} data={results} />
    </div>
  )
}

export default ParticipantSearch
