import React from "react"
import { Skeleton } from "antd"

const LoadingCard = ({ img }) => {
  return (
    <div className="loading-card">
      {img && <div className="loading-img" />}
      <Skeleton active paragraph={{ rows: 1 }} />
    </div>
  )
}

export default LoadingCard
