import React, { Component } from "react"
import { Form, Icon, Input, Button, Alert, Checkbox, Card } from "antd"
import { login } from "../services/authService"

class LoginForm extends Component {
  state = { errors: {} }

  handleSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await login(values.email, values.password)
          const { state } = this.props.location
          window.history.replaceState(null, null, "/")
          window.location = state ? state.from.pathname : "/"
        } catch (errors) {
          this.setState({ errors })
          global.log(errors)
        }
        global.log("Received values of form: ", values)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { errors } = this.state
    return (
      <Card bordered={false} title="Login" className="login-card">
        <Form hideRequiredMark>
          {errors.response && (
            <Alert
              message={errors.response.data}
              className="login-error"
              type="error"
              showIcon
            />
          )}
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Please input your username!"
                },
                {
                  type: "email",
                  message: "Please enter a valid address"
                }
              ]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Username"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Please input your Password!"
                }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>

          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true
          })(<Checkbox>Husk mig</Checkbox>)}
          {/* <a className="login-form-forgot" href="">
          Glemt adgangskode?
          </a> */}

          <Button
            type="primary"
            onClick={this.handleSubmit}
            className="login-form-button"
          >
            Login
          </Button>
          {/* Or <a href="#">register now!</a> */}
        </Form>
      </Card>
    )
  }
}

export default Form.create()(LoginForm)
