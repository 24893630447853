import React, { useState } from "react"
import { Statistic, Icon, Typography } from "antd"

const { Countdown } = Statistic
const { Paragraph } = Typography

const CountDown = ({ response }) => {
  const { urls, countDownSecunds, message } = response
  const deadline = Date.now() + countDownSecunds * 1000
  const [isFinishd, setIsFinishd] = useState(false)
  const handleCountdown = () => {
    urls.forEach(url => window.open(url.url, "_blank"))
    setIsFinishd(true)
  }
  return (
    <div className="text-center">
      {message && <Paragraph>{message}</Paragraph>}
      {isFinishd ? (
        urls.map((url, index) => (
          <Paragraph ellipsis key={url.id || index}>
            <a href={url.url}>
              <Icon type="link" /> {url.url}
            </a>
          </Paragraph>
        ))
      ) : (
        <Countdown value={deadline} format="ss" onFinish={handleCountdown} />
      )}
    </div>
  )
}

export default CountDown
