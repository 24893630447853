export const initialState = {
  contactType: "person",
  persons: [],
  companies: [],
  selectedPerson: {},
  selectedCompany: {},
  isLoading: false,
  isErrorList: null,
  isErrorContact: null,
  reset: false,
  listTitle: "",
  searchQuery: "",
  perservedQuery: "",
  searchMode: false,
  filterBy: null
}

export const reducer = (state, action) => {
  switch (action.type) {
    case "CompanySelected": {
      const newItem = action.payload
      const currentCompanies = state.companies || []
      if (currentCompanies.some(p => p.id === newItem.id))
        return {
          ...state,
          isLoading: false,
          selectedCompany: newItem
        }

      return {
        ...state,
        contactType: "company",
        searchMode: true,
        isLoading: false,
        companies: [newItem],
        perservedQuery: newItem.name,
        selectedCompany: newItem
      }
    }
    case "PersonSelected": {
      const newItem = action.payload
      const currentPersons = state.persons || []
      if (currentPersons.some(p => p.id === newItem.id))
        return {
          ...state,
          isLoading: false,
          selectedPerson: newItem
        }

      return {
        ...state,
        contactType: "person",
        searchMode: true,
        isLoading: false,
        persons: [newItem],
        perservedQuery: newItem.name,
        selectedPerson: newItem
      }
    }
    case "ListCompanies":
      return {
        ...state,
        contactType: "company",
        isLoading: false,
        companies: action.payload
      }
    case "ListPersons": {
      const { payload: persons } = action
      const selected = state.selectedPerson
      const selectedPerson =
        selected.id && persons && persons.some(p => p.id === selected.id)
          ? selected
          : {}
      return {
        ...state,
        contactType: "person",
        isLoading: false,
        persons,
        selectedPerson
      }
    }
    case "ListFilteredPersons": {
      return {
        ...state,
        contactType: "person",
        isLoading: false,
        persons: action.payload,
        selectedPerson: {},
        perservedQuery: "",
        searchMode: false,
        filterBy: action.payload[0] && action.payload[0].company
      }
    }
    case "ListCompaniesResults":
      return {
        ...state,
        isLoading: false,
        companies: action.payload,
        selectedCompany: {},
        perservedQuery: state.searchQuery,
        searchMode: true
      }
    case "ListPersonsResults": {
      return {
        ...state,
        isLoading: false,
        persons: action.payload,
        selectedPerson: {},
        perservedQuery: state.searchQuery,
        searchMode: true
      }
    }
    case "SearchQuery":
      return {
        ...state,
        searchQuery: action.payload
      }
    case "FetchingData":
      return {
        ...state,
        isLoading: true
      }
    case "FetchingListFailed":
      return {
        ...state,
        isErrorList: action.payload,
        isLoading: false
      }
    case "FetchingContactFailed":
      return {
        ...state,
        isErrorContact: action.payload,
        isLoading: false
      }
    case "TypeChange":
      return {
        ...state,
        selectedCompany: {},
        selectedPerson: {},
        perservedQuery: "",
        searchMode: false,
        filterBy: null,
        contactType: action.payload
      }
    case "ResetSearch":
      return {
        ...state,
        selectedCompany: {},
        selectedPerson: {},
        perservedQuery: "",
        searchMode: false,
        reset: !state.reset
      }
    case "ResetFilter":
      return {
        ...state,
        selectedCompany: {},
        selectedPerson: {},
        perservedQuery: "",
        searchMode: false,
        filterBy: null,
        reset: !state.reset
      }
    default:
      throw new Error(`unhandled contact action type ${action.type}`)
  }
}
